//登录页面
<template>
  <div class="create_class_dialog">
    <CommonDialog @close="close" :showDialog="showDialog" :title="title" :width="width">
      <template v-slot:content>
        <div class="create_class_dialog_content">
          <!-- 登录弹窗 -->
          <div class="login-top" v-if="loginType == 0">
            <div class="top">
              <el-input maxlength="11" type="phone" placeholder="请输入手机号" v-model="phoneNumber" clearable @input="changeButton()" />
            </div>
            <div class="top">
              <el-input type="password" placeholder="请输入密码" v-model="passWord" clearable @input="changeButton" />
            </div>
            <div class="login-info">
              <el-checkbox v-model="savePassword"><span class="save-pwd-text">记住密码</span></el-checkbox>
              <span class="register">
                <!-- <a
                  v-if="loginType == 0"
                  href="javascript:;"
                  @click="setLoginType(1)"
                  >注册</a
                >
                <a> | </a> -->
                <a v-if="loginType == 0" href="javascript:;" @click="setLoginType(2)">忘记密码</a>
              </span>
            </div>
            <!-- 隐私协议 -->
            <div class="user_agreement">
              <span>登录即代表同意</span>

              <span class="user_agreement_detail" @click="userAgreement()">《用户服务协议》</span>
              <!-- <span class="user_agreement_detail" @click="userAgreement()"
                >《隐私保护协议》</span
              > -->
            </div>

            <!-- 底部的登录按钮 -->
            <div class="bom_action">
              <el-button
                @click.stop="doCommitAction"
                :loading="showCommitLoading"
                :class="[isCommitBtn == true ? 'commit' : 'de_commit']"
                :disabled="!isCommitBtn"
              >
                <span style="font-size: 16px;font-weight: bold">登</span><span style="margin-left: 16px;font-size: 16px;font-weight: bold">录</span>
              </el-button>
            </div>
            <!-- 微信扫码登录 -->
            <div class="QR_code_login">
              <div class="iconfont icon cloud-General-2-63" @click="setLoginType(3)"></div>
            </div>
          </div>

          <!-- 注册弹窗 -->
          <div v-if="loginType == 1">
            <div class="top">
              <el-input maxlength="11" type="phone" placeholder="请输入手机号" v-model="phoneNumber" clearable @input="changeCodeButton" />
            </div>

            <div class="code-content">
              <el-input
                class="code-input"
                v-model="userCode"
                placeholder="请输入验证码"
                clearable
                autocomplete="new-password"
                @input="changeCodeButton"
              ></el-input>
              <el-button
                class="code-button default-button"
                :disabled="codeDisabled"
                @click="sendCode(0)"
                v-text="codeDisabled ? sendCodeTime + 's' : '获取验证码'"
              ></el-button>
            </div>
            <div class="top">
              <el-input placeholder="请输入新密码" v-model="regPassWord" clearable @input="changeCodeButton" />
            </div>

            <!-- 底部的取消和确定按钮 -->
            <div class="bom_action">
              <!-- 确定 -->
              <el-button
                :loading="showCommitLoading"
                :class="[isDefineBtn == true ? 'commit' : 'de_commit']"
                @click.stop="doCommitAction"
                :disabled="!isDefineBtn"
              >
                <span>确</span><span style="margin-left: 16px">定</span>
              </el-button>
            </div>

            <!-- 跳回登录 -->
            <div class="go-login">
              <span>已经有账号?马上</span>
              <span class="user_agreement_detail" @click="setLoginType(0)">登录</span>
            </div>
          </div>

          <!-- 重置密码弹窗 -->
          <div v-if="loginType == 2">
            <div class="top">
              <el-input maxlength="11" type="phone" placeholder="请输入手机号" v-model="phoneNumber" clearable @input="changeCodeButton" />
            </div>

            <div class="code-content">
              <el-input
                class="code-input"
                v-model="userCode"
                placeholder="请输入验证码"
                clearable
                autocomplete="new-password"
                @input="changeCodeButton"
              ></el-input>
              <el-button
                class="code-button default-button"
                :disabled="codeDisabled"
                @click="sendCode(0)"
                v-text="codeDisabled ? sendCodeTime + 's' : '获取验证码'"
              ></el-button>
            </div>
            <div class="top">
              <el-input placeholder="请输入新密码" v-model="regPassWord" clearable @input="changeCodeButton" />
            </div>

            <!-- 底部的取消和确定按钮 -->
            <div class="bom_action">
              <!-- 确定 -->
              <el-button
                :loading="showCommitLoading"
                :class="[isDefineBtn == true ? 'commit' : 'de_commit']"
                @click.stop="doCommitAction"
                :disabled="!isDefineBtn"
              >
                <span style="font-size: 14px;font-weight: bold;">确</span><span style="margin-left: 16px;font-size:14px;font-weight: bold">定</span>
              </el-button>
            </div>

            <!-- 跳回登录 -->
            <div class="go-login">
              <span>已经有账号?马上</span>
              <span class="user_agreement_detail" @click="setLoginType(0)">登录</span>
            </div>
          </div>

          <!-- 微信登录弹窗 -->
          <div v-if="loginType == 3" class="wechat-content">
            <!-- 加载框部分 -->
            <Loading v-if="showLoading"></Loading>

            <!-- 内容部分 -->
            <div class="layout-content" v-else>
              <div class="wechat-go-login" @click="setLoginType(0)">
                使用账号登录
              </div>

              <div class="login-info">
                <div class="wechat-code-img" id="qrcodeLogin" ref="qrcodeLogin"></div>

                <div class="weChat-code-item" style="margin-top: 18px;">
                  <span class="weChat-code-text">微信</span>
                  <span>扫码登录</span>
                </div>
                <div class="weChat-code-item">
                  <span>第一次登录请点击</span>
                  <span class="weChat-code-text">【关注公众号】</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";
import QRCode from "qrcodejs2";
// import loginApi from '@/api/login';

export default {
  data() {
    return {
      //宽度
      width: this.dialogWidth,
      //是否显示确定按钮加载框的标识
      showCommitLoading: false,
      //状态 登录0  注册1  忘记密码2 微信登录3
      loginType: 0,
      title: "",
      //手机号
      phoneNumber: "",
      //密码
      passWord: "",
      //注册密码
      regPassWord: "",
      //记住密码
      savePassword: false,
      //验证码
      userCode: "",
      //发送验证码 false 可点击 true 禁止点击
      codeDisabled: false,
      //验证码倒计时
      sendCodeTime: 300,
      codeTimer: "",
      // 正则验证 手机号和密码
      numReg: /^1[3456789]\d{9}$/,
      // passWordReg: /^[a-zA-Z]([-_a-zA-Z0-9]{6,20})$/,
      passWordReg: /^[0-9A-Za-z]{6,20}/,
      //登录提交按钮是否可选
      isCommitBtn: false,
      //注册密码是否可点击提交
      isDefineBtn: false,
      url: "", //二维码地址
      sceneId: "1", //二维码带的参数，用来判断是那个用户扫码的
      timer: null, //定时器
      //是否显示切换布局
      showLoading: false
    };
  },
  created() {},
  mounted() {
    //初始化状态
    this.initState();
  },

  methods: {
    //初始化状态
    initState() {
      if (!this.isEmpty(localStorage.getItem("phoneNumber"))) {
        this.phoneNumber = localStorage.getItem("phoneNumber");
      }

      if (!this.isEmpty(localStorage.getItem("passWord"))) {
        this.passWord = localStorage.getItem("passWord");
        this.savePassword = true;
        this.changeButton();
      } else {
        this.passWord = "";
        this.savePassword = false;
      }
    },

    showUploadLoading() {
      //进行文件的上传并显示Loading效果
      this.loading = this.$loading({
        lock: false,
        text: "",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)"
      });
    },

    //关闭Loading的方法
    closeLoading() {
      //上传成功后关闭loading
      if (!this.isEmpty(this.loading)) {
        this.loading.close();
      }
    },

    // 获取微信登录二维码
    async getLoginQRCode() {
      this.url = "";
      window.clearInterval(this.timer);
      // const res = await loginApi.getQRCode();
      let param = new Object();
      param.type = 14;
      let res = await this.$http.fetchPost(this.$api.LOGIN, param);
      // console.log("微信二维码接口地址", res);
      // if (res.data.state == "200") {
      this.url = res.data.data.url;
      this.sceneId = res.data.scene_id;
      console.log("sceneId", res.data.scene_id);
      this.timer = setInterval(this.getUserByScene, 2000);
      // this.closeLoading();
      this.showLoading = false;
      this.$nextTick(() => {
        this.getQrcode(this.url);
      });
      // }
    },

    //根据 sceneId 轮询查询用户信息，判断用户是否登录成功
    async getUserByScene() {
      if (!this.sceneId) return;

      let param = new Object();
      param.type = 9;
      param.code = this.sceneId;
      let res = await this.$http.fetchPost(this.$api.LOGIN, param);
      // console.log("getUser", res);
      if (res.data.state == "200" && res.data.userInfo) {
        this.userInfo = res.data.userInfo;
        window.clearInterval(this.timer);
        this.url = "";
        this.sceneId = "";
        //登录成功，处理登录成功逻辑
        // this.showSuccessMsg("登录成功");
        let userInfo = res.data.userInfo;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        //  localStorage.setItem("autoLogin", true);
        sessionStorage.setItem("autoLogin", true);

        //同步vuex的数据
        this.initVuexData(userInfo);

        //登录成功跳转页面
        this.$emit("doLoginCommit");
      }
    },

    //同步vueX的数据
    initVuexData(userInfo) {
      console.log(`请求到的登录用户信息是:`, userInfo);
      //Vuex存储用户信息和下拉的数组信息
      //根据用户角色来配置弹出框的数据
      let navArr = [];
      navArr.push({ name: "用户资料" });
      //0学生2老师-1游客 3校长 4管理员
      let roleType = userInfo.role;
      // console.log("test", "当前登录角色是:" + roleType);
      if (roleType == 0 || roleType == -1) {
        //学生或者游客都显示我的班级
        navArr.push({ name: "我的班级" });
      } else if (roleType == 2) {
        //老师
        navArr.push({ name: "班级管理" });
      }
      navArr.push({ name: "退出登录" });

      this.$store.commit("user/setLoginState", true);
      this.$store.commit("user/setUserInfo", userInfo);
      this.$store.commit("user/setNavArr", navArr);
    },

    //获取二维码
    getQrcode(url) {
      this.$refs.qrcodeLogin.innerHTML = ""; // 清除
      new QRCode("qrcodeLogin", {
        width: 160,
        height: 160,
        text: url, // 二维码地址
        colorDark: "#000",
        colorLight: "#fff"
      });
      return;
    },

    //登录是否按钮可点击
    changeButton() {
      if (this.phoneNumber != "" && this.passWord != "") {
        this.isCommitBtn = true;
      } else {
        this.isCommitBtn = false;
      }
    },

    //是否确定按钮可点击
    changeCodeButton() {
      if (this.phoneNumber != "" && this.regPassWord != "" && this.userCode != "") {
        this.isDefineBtn = true;
      } else {
        this.isDefineBtn = false;
      }
    },

    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");

      //数据初始化
      this.init();
    },

    init() {
      this.loginType = 0;
      this.regPassWord = "";
      this.userCode = "";
      this.showCommitLoading = false;
      window.clearInterval(this.timer);
      this.sendCodeTime = 300;
      this.codeDisabled = false;
      clearInterval(this.codeTimer);
    },

    //数据判断
    isCheck() {
      if (!this.numReg.test(this.phoneNumber)) {
        this.showWarnMsg("手机号格式不对");
        return false;
      }

      return true;
    },

    //注册重置密码的按钮操作
    doCommitAction() {
      this.showCommitLoading = true;

      //检查输入内容格式
      if (this.isCheck()) {
        if (this.loginType == 0) {
          if (!this.passWordReg.test(this.passWord)) {
            this.showWarnMsg("请输入6-20位数字或者字母密码");
            this.showCommitLoading = false;
          } else {
            //登录接口
            this.loginHttp();
          }
        } else if (this.loginType == 1) {
          if (!this.passWordReg.test(this.regPassWord)) {
            this.showWarnMsg("请输入6-20位数字或者字母密码");
            this.showCommitLoading = false;
          } else {
            //注册接口
            this.regHttp();
          }
        } else if (this.loginType == 2) {
          if (!this.passWordReg.test(this.regPassWord)) {
            this.showWarnMsg("请输入6-20位数字或者字母密码");
            this.showCommitLoading = false;
          } else {
            //重置密码
            this.modpwdHttp();
          }
        }
      } else {
        //更改showCommitLoading的状态
        this.showCommitLoading = false;
      }
    },

    //用户协议
    userAgreement() {
      window.open("https://zk.zdwkj.com/xieyi/terms_guaguayun.html", "_blank");
    },

    //切换登录模式
    setLoginType(type) {
      this.changeButton();
      this.changeCodeButton();

      if (type == 0) {
        this.title = "";
        this.loginType = 0;
        window.clearInterval(this.timer);
        this.regPassWord = "";
      } else if (type == 1) {
        this.title = "注册";
        this.loginType = 1;
      } else if (type == 2) {
        this.title = "重置密码";
        this.loginType = 2;
      } else if (type == 3) {
        this.title = "";
        this.loginType = 3;
        //调用接口生成微信登录二维码
        // this.showUploadLoading();
        //显示切换布局
        this.showLoading = true;
        this.getLoginQRCode();
        // this.getQrcode(this.url);
      }
    },

    sendCode() {
      //验证码手机号判断
      if (!this.numReg.test(this.phoneNumber)) {
        this.showWarnMsg("手机号格式不对");
        return;
      }
      //验证码接口
      this.getCodeHttp();
    },

    timeCountDown() {
      // 倒计时
      var that = this;
      var time = this.sendCodeTime;
      if (this.sendCodeTime === 0) {
        this.sendCodeTime = 300;
        this.codeDisabled = false;
        clearInterval(this.codeTimer);
      } else {
        this.sendCodeTime--;
        this.codeTimer = setTimeout(function() {
          that.timeCountDown();
        }, 1000);
      }
    },

    //登录接口
    async loginHttp() {
      var _that = this;
      let param = new Object();
      param.type = 1;
      param.tel = this.phoneNumber;
      param.p = this.passWord;
      let res = await this.$http.fetchPost(this.$api.LOGIN, param);
      if (res.data.state == 200) {
        //改变加载状态
        this.showCommitLoading = false;
        // this.showSuccessMsg("登录成功");
        var userInfo = res.data.userInfo;
        localStorage.setItem("phoneNumber", this.phoneNumber);
        if (this.savePassword) {
          localStorage.setItem("passWord", this.passWord);
        } else {
          localStorage.setItem("passWord", "");
        }

        localStorage.setItem("userInfo", JSON.stringify(userInfo));

        sessionStorage.setItem("autoLogin", true);

        //同步vueX的数据
        this.initVuexData(userInfo);

        //登录成功跳转页面
        this.$emit("doLoginCommit");
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
        this.showCommitLoading = false;
      }
    },

    //注册接口
    async regHttp() {
      var _that = this;
      let param = new Object();
      param.type = 3;
      param.tel = this.phoneNumber;
      param.code = this.userCode;
      param.state = "0";
      param.p = this.regPassWord;
      let res = await this.$http.fetchPost(this.$api.LOGIN, param);
      if (res.data.state == 200) {
        //改变加载状态
        this.showCommitLoading = false;

        this.showSuccessMsg("注册成功");

        window.clearInterval(this.timer);
        this.sendCodeTime = 300;
        this.codeDisabled = false;
        clearInterval(this.codeTimer);

        //返回登录页
        this.setLoginType(0);
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
        this.showCommitLoading = false;
      }
    },

    //重置密码接口
    async modpwdHttp() {
      var _that = this;
      let param = new Object();
      param.type = 4;
      param.tel = this.phoneNumber;
      param.code = this.userCode;
      param.state = "1";
      param.p = this.regPassWord;
      let res = await this.$http.fetchPost(this.$api.LOGIN, param);
      if (res.data.state == 200) {
        //改变加载状态
        this.showCommitLoading = false;

        this.showSuccessMsg("密码重置成功");
        window.clearInterval(this.timer);
        this.sendCodeTime = 300;
        this.codeDisabled = false;
        clearInterval(this.codeTimer);
        //返回登录页
        this.setLoginType(0);
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
        this.showCommitLoading = false;
      }
    },

    //获取验证码接口
    async getCodeHttp() {
      var codetype = -1;
      if (this.loginType == 1) {
        //注册验证码
        codetype = 0;
      } else if (this.loginType == 2) {
        //改密码验证码
        codetype = 1;
      } else if (this.loginType == 3) {
        //绑定微信验证码
        codetype = 3;
      }

      var _that = this;
      let param = new Object();
      param.type = 2;
      param.tel = this.phoneNumber;
      param.role = "0";
      param.state = codetype;

      let res = await this.$http.fetchPost(this.$api.LOGIN, param);
      if (res.data.state == 200) {
        //倒计时
        this.timeCountDown();
        this.codeDisabled = true;

        this.showSuccessMsg("验证码发送成功!");
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
      }
    }
  },
  computed: {},
  components: {
    CommonDialog
  },
  props: ["showDialog"]
};
</script>
<style lang="scss" scoped>
.create_class_dialog {
  .create_class_dialog_content {
    display: flex;
    justify-content: center;
    color: black;
    flex-direction: column;
    .login-top {
      padding-top: 17px;
    }
    //顶部部分
    .top {
      display: flex;
      justify-content: center;
      ::v-deep .el-input {
        width: 80%;
        border: none;
        margin-top: 12px;
      }

      //左右间距
      ::v-deep .el-input__inner {
        padding: 0 8px;
      }

      //获取焦点的颜色
      ::v-deep .el-input__inner:focus {
        border-color: #dcdfe6;
      }

      //移入时的颜色
      ::v-deep .el-input__inner:hover {
        border-color: #dcdfe6;
      }
    }

    .user_agreement {
      font-size: 14px;
      color: #a2a2a2;
      margin-left: 34px;
      margin-top: 53px;
    }

    .user_agreement_detail {
      font-size: 14px;
      color: #43bb61;
      cursor: pointer;
    }
    //底部的操作按钮
    .bom_action {
      margin-top: 15px;
      height: 38px;
      display: flex;
      font-size: 16px;
      justify-content: center;

      .commit {
        width: 270px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        background: $common_bg;
        color: white;
      }

      .de_commit {
        width: 270px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background: gray;
        color: white;
      }
    }

    .QR_code_login {
      margin-top: 10px;
      text-align: right;
      width: 100%;

      .icon {
        float: right;
        font-size: 22px;
        margin-right: 5px;
        cursor: pointer;
      }
    }

    .login-info {
      margin-top: 15px;
      margin-left: 34px;
      text-align: center;
      width: 80%;
      font-size: 10px;
    }

    .save-pwd-text {
      font-size: 14px;
      color: #a2a2a2;
    }
    .login-info .el-checkbox {
      float: left;
    }

    /deep/.login-info .el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: #43bb61;
    }

    .login-info .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #000;
    }
    /deep/.login-info .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #43bb61;
      border-color: #43bb61;
    }

    .login-info .register a {
      color: #a2a2a2;
      padding: 0 3px;
      font-size: 14px;
      // font: size 10px;
      float: right;
    }
  }
  .code-content {
    width: 80%;
    display: flex;
    justify-content: center;
    margin-left: 33px;
    margin-top: 10px;

    .code-input {
      width: 180px;
      margin-right: 10px;
    }

    .code-button {
      width: 85px;
      font-size: 12px;
    }

    .code-button.is-disabled {
      background-color: #eeeeee;
    }
  }
  .default-button {
    height: 40px;
    font-size: 16px;
    background-color: #fff;
    border-color: #43bb61;
    border: 1;
    padding: 0;
    margin: 0;
    color: #43bb61;
    font-size: 14px;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
  }
  .go-login {
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: #a2a2a2;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .wechat-content {
    .loading {
      height: 265px;
    }
  }

  .wechat-go-login {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #43bb61;
    cursor: pointer;
  }
  .weChat-code-text {
    font-size: 14px;
    color: orangered;
  }

  .weChat-code-item {
    margin-top: 14px;
    margin-bottom: 12px;
    font-size: 14px;
  }
  .wechat-code-img {
    width: 150px;
    height: 150px;
    margin-left: 20%;
  }
}
</style>
